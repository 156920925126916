import React from "react"
import { Link } from "gatsby"

const Footer = ({ data }) => (
  <footer className="row">
    <div className="col-xs-12 col-sm-6 col-centered">
      <img
        className="logo"
        src="/img/rv-monogram.svg"
        alt="Monogramm Luka Rivic"
      />
      <div className="center">
        <p>
          <strong>Rivic Visuals.</strong>
        </p>
        <Link to="/impressum">Impressum</Link> —{" "}
        <a
          title="Allgemeine Geschäftsbedingungen"
          href="/files/05-AGB-RV-Juni-2020.pdf"
        >
          AGB
        </a>
      </div>
      <div className="right">
        <a
          title="Instagram Profil von Luka Rivic."
          className="social"
          href="https://www.instagram.com/if.rivic/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img alt="LinkedIn Logo" src="/img/ig.svg" className="social" />
        </a>
        <a
          title="LinkedIn Profil von Luka Rivic."
          className="social"
          href="https://at.linkedin.com/in/luka-rivic-81ab7b10a"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img alt="LinkedIn Logo" src="/img/linkedin.svg" className="social" />
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
